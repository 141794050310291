
import { defineComponent } from "vue";
import AboutMenu from "./AboutMenu.vue";
import AboutMenuItem from "./AboutMenuItem.vue";

export default defineComponent({
  setup() {
    return {};
  },
  components: {
    AboutMenu,
    AboutMenuItem
  }
});
