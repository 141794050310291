import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_about_menu_item = _resolveComponent("about-menu-item")!
  const _component_about_menu = _resolveComponent("about-menu")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_about_menu, null, {
      default: _withCtx(() => [
        _createVNode(_component_about_menu_item, { path: "/about/experience" }, {
          option: _withCtx(() => [
            _createTextVNode("Experience")
          ]),
          _: 1
        }),
        _createVNode(_component_about_menu_item, { path: "/about/projects" }, {
          option: _withCtx(() => [
            _createTextVNode("Projects")
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}