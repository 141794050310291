
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  setup(props) {
    const router = useRouter();

    function handleClick() {
      if (props.path !== null) {
        router.push(props.path);
      }
    }

    return {
      handleClick
    };
  },
  props: {
    path: {
      default: null,
      type: String
    }
  }
});
